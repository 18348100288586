export const Environment = {
	PRODUCTION: false,
	CLIENT_ID: 'development',
	DEFAULT_ROUTE: null,
	TEST: false,
	VERSION: '0.349.0',
	MODULES: [
		'atex-inspections',
		'digital-twin',
		'asset-planning',
		'gap-analysis',
		'dl-50',
		'inspections',
		'observations',
		'pipeline-integrity',
		'epi',
		'repairs'
	],
	TIMESTAMP: '2024-12-12T10:29:52.035Z',
	COMMIT: 'd54b6d2da6d77ce097a49294a16b0bfe94d56283',
	BRANCH: 'development',
	API_SERVER: 'https://development-eqs-digital-api.unoplatform.io',
	MAPBOX_TOKEN: 'pk.eyJ1IjoidW5vM2QiLCJhIjoiY2swbWk3ZDU1MTQ2aTNubXprbnZ6Y2c1bCJ9.fGhQreB4AkAUcW_5_tr81Q',
	RESOURCE_SERVER: 'https://development-eqs-digital-resources.unoplatform.io',
	FILE_CONVERTER_SERVER: 'https://development-eqs-digital-file-converter.unoplatform.io',
	PIPELINE_INTEGRITY_SERVER: 'https://development-eqs-digital-pipeline-integrity.unoplatform.io',
	DIGITAL_TWIN_SERVER: 'https://development-eqs-digital-digital-twin.unoplatform.io'
};
